/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';
import Image from '@nubank/nuds-web/components/Image/Image';

import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';

import FormBox from '../../../../components/FormBox/FormBox';
import { HERO_IMAGE_EXPERIMENT_ID, HERO_IMAGE_EXPERIMENT_VARIANT_ID } from '../../../../utils/experiment';

const HERO_IMAGE = {
  xs: 'masterbrand-hero/mb-hero-xs-experiment.jpg',
  lg: 'masterbrand-hero/mb-hero-lg-experiment.jpg',
};

const HERO_IMAGE_EXPERIMENTAL = {
  xs: 'masterbrand-hero/home-hero-experimental-mobile.jpg',
  lg: 'masterbrand-hero/home-hero-experimental-desktop.jpg',
};

const StyledTypography = styled(Typography)`
  /* stylelint-disable property-no-vendor-prefix, property-no-unknown */
  ${props => props.activeVariant && css`
    background: linear-gradient(99.92deg, #6200A3 18.57%, #9F2DEB 71.77%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `}

  ${breakpointsMedia({
    xs: css`
      font-size: 32px;
    `,
    md: css`
      font-size: 45px;
    `,
    lg: css`
      font-size: 4.5vw;
    `,
  })}

  @media (width <= 375px) {
    margin-top: 60px
  }


`;

const StyledBox = styled(Box)`
  picture {
    width: 100%;
  }
  
  ${props => props.activeVariant && css`
    img {
      border-radius: 0 0 30px 30px;

      @media (width >= 768px) {
        border-radius: 0 0 50px 50px;
      }
    }
  `}
`;

const StyledSubtitle = styled(Typography)`
  ${props => props.activeVariant && css`
    background: linear-gradient(99.92deg, #6200A3 18.57%, #9F2DEB 71.77%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `}
`;

const HeroSection = () => {
  const [screenHeight, setScreenHeight] = useState(0);
  const activeVariant = useFigPiiExperiment(HERO_IMAGE_EXPERIMENT_ID);
  const imageSrcSet = activeVariant === HERO_IMAGE_EXPERIMENT_VARIANT_ID
    ? HERO_IMAGE_EXPERIMENTAL : HERO_IMAGE;

  useEffect(() => {
    setScreenHeight(window.innerHeight);
  }, []);

  return (
    <>
      <Box
        marginBottom={{ xs: '0', lg: '24x' }}
        maxWidth="100vw"
        tag="section"
        width="100%"
      >
        <StyledBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          height="100%"
          activeVariant={activeVariant === HERO_IMAGE_EXPERIMENT_VARIANT_ID}
        >
          <Image
            srcSet={imageSrcSet}
            alt="Imagen de dos tarjetas moradas flotando con un fondo violeta"
            height={{ xs: `${screenHeight}px`, lg: 'auto' }}
            width="100%"
            objectFit="cover"
            objectPosition="top"
          />

          <Box
            position="absolute"
            display="flex"
            flexDirection={{ xs: 'column' }}
            alignItems={{ xs: 'center', lg: 'flex-start' }}
            justifyContent={{ xs: 'space-between', lg: 'center' }}
            height={{ xs: `${screenHeight - (screenHeight / 25)}px`, lg: '100vh' }}
            paddingHorizontal={{ xs: '4x', md: '12x', lg: '16x' }}
            width="95vw"
          >
            <StyledTypography
              variant="heading1"
              tag="h1"
              marginBottom={{ xs: '6x', md: '18x', lg: '8x' }}
              marginTop={{ xs: '18x', lg: '0' }}
              color={activeVariant === HERO_IMAGE_EXPERIMENT_VARIANT_ID ? 'primary' : 'white'}
              textAlign={{ xs: 'center', lg: 'left' }}
              whiteSpace="pre-line"
              activeVariant={activeVariant === HERO_IMAGE_EXPERIMENT_VARIANT_ID}
            >
              {'100 millones en el \n mundo ya son parte \n de la era Nu, faltas tú'}
            </StyledTypography>

            <Box
              display="flex"
              flexDirection="column"
              alignItems={{ xs: 'center', lg: 'flex-start' }}
              justifyContent={{ xs: 'center', lg: 'flex-start' }}
            >

              <StyledSubtitle
                variant="subtitle1"
                whiteSpace={{ xs: 'inherit', lg: 'pre-line' }}
                tag="p"
                textAlign={{ xs: 'center', lg: 'left' }}
                color={activeVariant === HERO_IMAGE_EXPERIMENT_VARIANT_ID ? 'primary' : 'white'}
                strong
                maxWidth={{ xs: '342px', md: '380px', lg: '470px' }}
              >
                Tarjeta de Crédito Nu sin anualidad y con Cuenta Nu tu dinero está disponible 24/7
              </StyledSubtitle>

              <FormBox
                buttonLabel="HOME.MULTIPRODUCT.HERO.CTA.BTN"
                inputAndButtonWidth={{ xs: '335px' }}
                buttonBackgroundColor="#820AD1"
                buttonGradientColor="linear-gradient(99.92deg, #9F2DEB 18.57%, #6200A3 71.77%),linear-gradient(0deg, #2DB965, #2DB965)"
                buttonLabelColor="#FFFFFF"
                inputTransparentBackground
                inputLabelColor="#FFFFFF"
                ctaHide
                newDesign
              />
            </Box>
          </Box>

        </StyledBox>
      </Box>
    </>
  );
};

export default HeroSection;
